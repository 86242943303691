<template>
  <div class="top-header">
    <span class="nav-name" @click="backHandle">
      <i class="el-icon-arrow-left"></i>
      {{navName}}
    </span>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  props: {
    navName: {
      type: String,
      default: ""
    }
  },
  methods: {
    backHandle() {
      this.$emit("backHandle");
    }
  }
};
</script>

<style lang="scss" scoped>
.top-header {
  height: 50px;
  line-height: 50px;
  padding-left: 22px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  .nav-name {
    display: inline-block;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    .el-icon-arrow-left {
      position: relative;
      top: 2px;
      color: #a9b5c6;
      font-size: 20px;
      margin-right: 2px;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "top-header" }, [
    _c("span", { staticClass: "nav-name", on: { click: _vm.backHandle } }, [
      _c("i", { staticClass: "el-icon-arrow-left" }),
      _vm._v(" " + _vm._s(_vm.navName) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }